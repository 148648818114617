import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../Containers/Banner';
import Experience from '../../Containers/Experiences/Experience';
import FunFact01 from '../../Containers/Funfacts/FunFact01';
import ServiceHome01 from '../../Containers/ServiceContainers/ServiceHome01';
import Testimonial01 from '../../Containers/Testimonials/Testimonial01';
import Slider from '../../Containers/ThemeSliders/Slider';

const Home01 = () => {
    return (
        <div className="main_wrapper">
            <Slider />
            <ServiceHome01 />
            <Experience />
            <FunFact01 />

            {/* <ProjectHome01 /> */}

            <Banner
                title='Royal Villa Homes is proud to serve you 24/7. Just Call Us when you need'
                heading='Call Us Anytime'
                phone='+1-(780)-517-7777 +1-(780)-965-5819'
                bannerType='banner type_3'
            />

            <Testimonial01 pb='pd_btom_80' />

            {/* <Blog /> */}
            {/* <Partners01 /> */}
            <Helmet>
            <title>
      Top Home Builder in Edmonton, AB | Royal Villa Homes
  </title>
  <meta
      name="description"
      content="Royal Villa Homes is the top home builder in Edmonton, AB, offering custom home design, quality construction, and exceptional craftsmanship to create your dream home."
  />
  
  <meta
      name="keywords"
      content="top home builder,
      best home builder,
      best home builders,
      builders in edmonton,
      best house builders,
      top home builders,
      custom home builders edmonton,
      best custom home builders,
      best quality home builders,
      top home builder,
      houses to be moved edmonton,
      top 10 list new home builders,
      home builders edmonton,
      edmonton home builders,
      edmonton builders,
      new home builders edmonton,
      best new home builders,
      the best home builders,
      best new home builder,
      top rated new home builders,
      what home builder is the best,
      top house builders,
      top 10 house builders"
  />
  <meta property="og:title" content="Top Home Builder in Edmonton, AB | Royal Villa Homes" />
  <meta
      property="og:description"
      content="Royal Villa Homes is the top home builder in Edmonton, AB, offering custom home design, quality construction, and exceptional craftsmanship to create your dream home."
  />
  <meta property="og:image" content="/images/logo.jpg" /> 
  <meta property="og:url" content="https://royalvillahomes.ca" />
  <meta property="og:type" content="website" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Top Home Builder in Edmonton, AB | Royal Villa Homes" />
  <meta
      name="twitter:description"
      content="Royal Villa Homes is the top home builder in Edmonton, AB, offering custom home design, quality construction, and exceptional craftsmanship to create your dream home."
  />
  <meta name="twitter:image" content="/images/logo.jpg" /> 
            </Helmet>
        </div>
    );
};

export default Home01;