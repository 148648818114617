import React from 'react';
import PageHeader from '../Components/common/PageHeader';
import Partners02 from '../Components/Partners/Partners02';
import TopFeatures from '../Components/TopFeatures';
import Banner from '../Containers/Banner';
import ExperienceAbout from '../Containers/Experiences/ExperienceAbout';
import FunFactAbout from '../Containers/Funfacts/FunFactAbout';
import Team from '../Containers/Team';
import Testimonial01 from '../Containers/Testimonials/Testimonial01';
import { Helmet } from 'react-helmet';

const About = () => {
    const AboutBannerData = {
        title: 'Builderrine is proud to serve you 24/7. Just Call Us when you need',
        heading: 'Call Us Anytime',
        phone: '(+1)780-517-7777'
    }
    return (
        <>
            <PageHeader activeClass01='active' activeClass02='d-none' routeName01='About Us' heading='About Company' />

            <div className="main_wrapper">
                <TopFeatures />

                <ExperienceAbout />

                <FunFactAbout />

                {/* <Team pb='pd_btom_110' /> */}

                <Banner
                    title='Is Your House Secured Enough? Call Us to install Security Devices'
                    heading='Call Us Anytime'
                    phone='+1-(780)-517-7777   +1-(780)-965-5819'
                    bannerType='banner type_3'
                />

                <Testimonial01 pb='pd_btom_110' />
                {/* <Partners02 pt='pd_tp_110' /> */}
            </div>
            <Helmet>
            <title>
      Our Story | Top Custom Home Builders in Edmonton, AB
  </title>
  <meta
      name="description"
      content="Learn about Royal Villa Homes, the top home builder in Edmonton, AB. Discover our commitment to quality craftsmanship, custom home design, and client satisfaction"
  />
  
  <meta
      name="keywords"
      content="top home builder,
      best home builder,
      best home builders,
      builders in edmonton,
      best house builders,
      top home builders,
      custom home builders edmonton,
      best custom home builders,
      best quality home builders,
      top home builder,
      houses to be moved edmonton,
      top 10 list new home builders,
      home builders edmonton,
      edmonton home builders,
      edmonton builders,
      new home builders edmonton,
      best new home builders,
      the best home builders,
      best new home builder,
      top rated new home builders,
      what home builder is the best,
      top house builders,
      top 10 house builders"
  />
  <meta property="og:title" content="Top Home Builder in Edmonton, AB | Royal Villa Homes" />
  <meta
      property="og:description"
      content="Royal Villa Homes is the top home builder in Edmonton, AB, offering custom home design, quality construction, and exceptional craftsmanship to create your dream home."
  />
  <meta property="og:image" content="/images/logo.jpg" /> 
  <meta property="og:url" content="https://royalvillahomes.ca" />
  <meta property="og:type" content="website" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Our Story | Top Custom Home Builders in Edmonton, AB" />
  <meta
      name="twitter:description"
      content="Learn about Royal Villa Homes, the top home builder in Edmonton, AB. Discover our commitment to quality craftsmanship, custom home design, and client satisfaction"
  />
  <meta name="twitter:image" content="/images/logo.jpg" /> 
            </Helmet>
        </>
    );
};

export default About;