import React from 'react';
import ServiceCard02_01 from '../../Components/ServiceCards/ServiceCard02_01';
import PaginationDiv from '../../Components/TeamElements/PaginationDiv';

const ServiceContainer01 = () => {
    const ServiceData01 = [
        {
            id: '1',
            img02: 'images/services/Service_Hover_images/Single home.png',
            tag: 'SINGLE FAMILY HOME',
            title: `At Royal Villa Homes, we specialize in crafting stunning single-family homes tailored to your lifestyle. A single-family home offers the ultimate privacy and independence, with no shared walls and ample outdoor space. These homes are perfect for families looking for a peaceful, spacious environment. Designed with versatility in mind, our single-family homes include customizable layouts, multiple bedrooms, and modern kitchens to suit your family’s needs. Located in Edmonton's most sought-after neighborhoods, these homes are ideal for raising children or enjoying serene suburban living.
`
          },
          {
            id: '2',
            img02: 'images/services/Service_Hover_images/Duplexes.png',
            tag: 'DUPLEX',
            title: 'Royal Villa Homes designs high-quality duplexes that blend affordability with functionality. These homes consist of two units with shared walls, making them perfect for families or individuals looking to share living expenses without compromising comfort. Duplexes offer private entrances and backyards, giving you the feel of a single-family home at a more affordable price. Built with energy efficiency and contemporary aesthetics in mind, our duplexes are ideal for growing families or rental property investors in Edmonton.',
          },
          {
            id: '3',
            img02: 'images/services/Service_Hover_images/Townhouses.png',
            tag: 'TOWN HOUSE',
            title: 'Our expertly designed townhouses provide the perfect mix of convenience and style. Townhouses are multi-level homes with shared walls, offering affordability and spacious layouts. Royal Villa Homes creates townhouses with open-concept living spaces, attached garages, and private patios. Ideal for young professionals, small families, or retirees, our townhouses are located in Edmonton\'s most vibrant communities. With minimal maintenance required, you\'ll have more time to enjoy life.'
          },
          {
            id: '4',
            img02: 'images/services/Service_Hover_images/Bungalows.png',
            tag: 'BUNGALOWS',
            title: 'Simplify your living with Royal Villa Homes\' expertly crafted bungalows in Edmonton. These single-story homes are perfect for retirees, first-time buyers, or families who prefer a compact, accessible layout. Our bungalows feature spacious open-concept designs, energy-efficient appliances, and cozy yet modern interiors. With the flexibility to include basements or additional outdoor space, bungalows offer charm and practicality in one package. Located in Edmonton\'s serene neighborhoods, our bungalows ensure easy living without compromising on comfort.'
          },
          {
            id: '5',
            img02: 'images/services/Service_Hover_images/Multi-Family Homes.png',
            tag: 'MULTI-FAMILY HOMES',
            title: 'Royal Villa Homes caters to the demand for multi-family living with thoughtfully designed homes. Whether you\'re an investor or an extended family, our multi-family homes include duplexes, triplexes, and fourplexes that maximize space and affordability. These homes are perfect for rental opportunities or shared living arrangements, offering privacy for each family while maintaining cost-effectiveness. Located in Edmonton\'s growing communities, multi-family homes provide a smart investment in quality living.'
          },
          // {
          //   id: '6',
          //   img02: 'images/services/Service_Hover_images/Laneway Homes.png',
          //   tag: 'LANEWAY HOMES',
          //   title: 'Make the most of your property with a laneway home from Royal Villa Homes. Built on the same lot as an existing home, laneway homes are ideal for rental income, guest housing, or creating a multi-generational living space. These compact homes feature modern designs, energy-efficient construction, and customizable layouts. Laneway homes are a smart solution for maximizing urban space without compromising comfort. Located in Edmonton\'s dynamic neighborhoods, they represent innovation in homebuilding.'
          // },
    ];

    return (
        <div className="main_wrapper">
            <div className="section service services_inner_page">
                <div className="container">
                    <div className="row">
                        {
                            ServiceData01.map(data =>
                                <ServiceCard02_01
                                    key={data.id}
                                    data={data}
                                />
                            )
                        }

                        {/* <PaginationDiv /> */}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceContainer01;