import React from 'react';
import AboutBelowContent from '../../Components/ExperienceBlocks/AboutBelowContent';
import { Link } from 'react-router-dom';

const Experience = () => {
    const headerData = {
        img: 'images/about/shadow_icon1.png',
        heading: 'Building A New Era in world of Construction',
        subheading: 'ABOUT ROYAL VILLA HOMES & CONSTRUCTIONS',
    };

    const ExpericenData = [
        {
            id: '1',
            title: 'Most Reliable',
            description: 'Trusted by numerous satisfied clients'
        },
        {
            id: '2',
            title: 'Cost Effective',
            description: 'Affordable solutions without compromising quality'
        },    
    ];

    return (
        <div className="experience section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="group_image_holder type_1">
                            <div className="expe_box">
                                <div className="expe_box_inner">
                                    <img src="images/logo.png" alt="logo" />
                                </div>
                            </div>
                            <div className="image_object">
                                <img src="images/about/1.png" alt="" />
                                <div className="object">
                                    <img src="images/about/3.png" alt="About" />
                                    <img src="images/about/3.png" alt="About" />
                                    <img src="images/about/3.png" alt="About" />
                                    <img src="images/about/s1.png" alt="About" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="experience_content">
                            <div className="section_header">
                                <div className="shadow_icon"><img src={headerData.img} alt="" /></div>
                                <h6 className="section_sub_title">{headerData.subheading}</h6>
                                <h1 className="section_title">{headerData.heading}</h1>
                                <p className="section_desc">"Royal Villa Homes is a safe, reliable, and cost-effective construction company offering top-notch building services. We specialize in delivering exceptional results for highrise and housing projects. Whether you're planning a new build or a large-scale development, you're in the right place for quality and expertise."</p>
                                <div className="about_below">
                                    {
                                        ExpericenData.map(data =>
                                            <AboutBelowContent
                                                key={data.id}
                                                data={data}
                                            />
                                        )
                                    }

                                </div>
                            </div>
                            <Link className="button" to="/about">Learn More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Experience;