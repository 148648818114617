import React, { useEffect, useState } from 'react';
import MobileNav from './MobileNav/MobileNav';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
    // Hide header on scroll down
    window.onscroll = function () { myFunction() }; function myFunction() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            // document.querySelector('header').classList.add('dark_mode');
            document.querySelector('header .top_bar').classList.add('top-up');
        } else {
            // document.querySelector('header').classList.remove('dark_mode');
            document.querySelector('header .top_bar').classList.remove('top-up');
        }
    }

    const [active, setActive] = useState("home");
    const location = useLocation();

    // Update the active state based on the current route
    useEffect(() => {
        const path = location.pathname.split("/")[1]; // Get the active path segment
        setActive(path || "home"); // Default to 'home' if the path is empty
    }, [location]);

    const handleToggle = (text) => {
        setActive(text);
    };

    return (
        <>
            <header className="header dark_mode">
                {/* <PreHeader /> */}

                <div className="top_bar" id='top-bar'>
                    <div className="container">
                        <div className="top_bar_inner">
                            <div className="header_social">
                                <h6>Follow Us</h6>
                                <ul className="top_social">
                                    <li className="facebook"><a href="#"><i className="ion-social-facebook"></i></a></li>
                                    {/* <li className="twitter"><a href="#"><i className="ion-social-twitter"></i></a></li> */}
                                    {/* <li className="dribbble"><a href="#"><i className="ion-social-dribbble-outline"></i></a></li> */}
                                    <li className="instagram"><a href="#"><i className="ion-social-instagram-outline"></i></a></li>
                                    {/* <li className="linkedin"><a href="#"><i className="ion-social-linkedin"></i></a></li> */}
                                </ul>
                            </div>
                            <div className="header_info">
                                <div className="schedule">
                                    <div className="hide_icon d-md-none" data-text="royalvillahomes@yahoo.com"> <span><i className="fa fa-envelope"></i></span></div>
                                    <a href="mailto:royalvillahomes@yahoo.com"><div className="d-none d-md-block"><span><i className="fa fa-envelope"></i></span> royalvillahomes@yahoo.com</div></a>
                                </div>
                                <div className="free_contact">
                                    <Link onClick={() => handleToggle('contact')} to="/contact" className="btn">Query?</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="middle_bar">
                    <div className="container">
                        <div className="middle_bar_inner">
                            <div className="logo">
                                <Link to="/" className="light_mode_logo"><img src="images/logo.png" alt="logo" /></Link>
                                <Link to="/" className="dark_mode_logo"><img src="images/logo_foot.png" alt="logo" /></Link>
                            </div>

                            <div className="header_right_part">
                                <div className="mainnav">
                                    <ul className="main_menu">
                                        <li onClick={() => handleToggle('home')} className={`menu-item ${active==='home' && 'active'}`}><Link to="/">Home</Link></li>
                                        <li onClick={() => handleToggle('residentials')} className={`menu-item  ${active==='residentials' && 'active'}`}><Link to="/residentials">Residential Construction</Link></li>
                                        <li onClick={() => handleToggle('project')} className={`menu-item  ${active==='project' && 'active'}`}><Link to="/project">Projects</Link></li>
                                        <li onClick={() => handleToggle('about')} className={`menu-item ${active==='about' && 'active'}`}><Link to="/about">About</Link></li>
                                        <li onClick={() => handleToggle('contact')} className={`menu-item ${active==='contact' && 'active'}`}><Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                                <div className="phone">
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                    <div><span>Call Us Anytime</span><br /><span className="phn_number">+1-(780)-517-7777</span> <br /><span className="phn_number">+1-(780)-965-5819</span></div>
                                </div>
                                {/* <div className='header_search' onClick={handleToggle}>
                                    <button type="submit" className="form-control-submit"><i className="ion-ios-search"></i></button>
                                </div> */}
                                {/* <div id='open_search' className={isActive ? null : "active"}>
                                    <form className="search_form" action="search.php">
                                        <input type="text" name="search" className="keyword form-control" placeholder="Search..." />
                                        <button type="submit" className="form-control-submit"><i className="ion-ios-search"></i></button>
                                    </form>
                                </div> */}

                                <button className="ma5menu__toggle position-relative d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasHome" aria-controls="offcanvasHome"
                                >
                                    <i className="ion-android-menu"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <MobileNav />

        </>
    );
};

export default Header;